import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CodeSandbox = makeShortcode("CodeSandbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`React hooks are becoming ever so popular with developers looking for a scalable way to share logic across their application, without the bloat of HOCs or render props.`}</p>
    <p>{`In this tutorial, we will create a React hook that is responsible for storing Net Promoter Score feedback. The hook won't be responsible for any UI, but the logic behind storing and sending the score to our provided function.`}</p>
    <p><img parentName="p" {...{
        "src": "https://thepracticaldev.s3.amazonaws.com/i/tjff1mtj8au98tq9j0tz.gif",
        "alt": "useNPS"
      }}></img></p>
    <p>{`First of all, let's start by defining how we'd like to use our hook. Our React hook needs to meet the following requirements;`}</p>
    <ul>
      <li parentName="ul">{`Tell me if I can submit a score or not`}</li>
      <li parentName="ul">{`Tell me if I have submitted a score or not`}</li>
      <li parentName="ul">{`Tell me the submitted score`}</li>
      <li parentName="ul">{`Allow me to submit a score`}</li>
      <li parentName="ul">{`Allow me to provide custom behaviour for `}<inlineCode parentName="li">{`onSubmit`}</inlineCode></li>
      <li parentName="ul">{`Allow me to provide custom behaviour for `}<inlineCode parentName="li">{`onDismiss`}</inlineCode></li>
      <li parentName="ul">{`Allow me to provide a default `}<inlineCode parentName="li">{`dismissed`}</inlineCode>{` value`}</li>
    </ul>
    <p>{`If we write some code to invoke our imaginary React hook, it should end up looking like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const [{ scored, score, dismissed }, { submit, dismiss }] = useNPS({
  dismissed: true, // Default to false
  onSubmit: (score) => alert(\`Thanks for your score \${score}\`),
  onDismiss: ({ scored, score }) =>
    alert(scored ? \`Thanks again!\` : \`Maybe next time?\`),
});
`}</code></pre>
    <p>{`In the above we tick each of our requirements, now let's begin to explore actually writing the code that handles the logic here.`}</p>
    <h2>{`Creating the hook`}</h2>
    <p>{`We'll be using the new `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook from React within our own hook, let's start by importing that and declaring our `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState } from "react";

function useNPS() {
  // ...
}
`}</code></pre>
    <p>{`Next let's invoke the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook to store our own state within `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS() {
  const [dismissed, setDismissed] = useState(false);
  const [score, setScore] = useState(undefined);
}
`}</code></pre>
    <p>{`Both `}<inlineCode parentName="p">{`dismissed`}</inlineCode>{` and `}<inlineCode parentName="p">{`score`}</inlineCode>{` are vital to our `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` function. Afterall, we're building a hook to capture NPS scores, we need to save that state!`}</p>
    <p>{`As with any React hook, we need to return some values. We have the choice to return an object or array, I'm going to follow the `}<inlineCode parentName="p">{`[getter, setter]`}</inlineCode>{` pattern for our hook and return what we need in an array, the first array object will contain our getter methods, while the second will contain our setter methods.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS() {
  const [dismissed, setDismissed] = useState(false);
  const [score, setScore] = useState(undefined);

  return [{ score, dismissed }, {}];
}
`}</code></pre>
    <p>{`Right now we don't have any setter methods, and we're missing a get method for `}<inlineCode parentName="p">{`scored`}</inlineCode>{` which is important so we know if we have submitted our form. We can easily do this by checking if there is a truthy `}<inlineCode parentName="p">{`score`}</inlineCode>{` value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS() {
  const [dismissed, setDismissed] = useState(false);
  const [score, setScore] = useState(undefined);
  const scored = !!score;

  return [{ score, scored, dismissed }, {}];
}
`}</code></pre>
    <p>{`We've now successfully completed and returned the three required getter methods; `}<inlineCode parentName="p">{`score`}</inlineCode>{`, `}<inlineCode parentName="p">{`scored`}</inlineCode>{` and `}<inlineCode parentName="p">{`dismissed`}</inlineCode>{`.`}</p>
    <p>{`Before we create our `}<inlineCode parentName="p">{`submit`}</inlineCode>{` and `}<inlineCode parentName="p">{`dismiss`}</inlineCode>{` methods, let's fix the initial value for `}<inlineCode parentName="p">{`dismissed`}</inlineCode>{`. Back when we defined our `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` function, we needed the ability to automatically set `}<inlineCode parentName="p">{`dissmised`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. This is useful if we don't want to show our form, our hook can control that behaviour.`}</p>
    <p>{`Let's destructure `}<inlineCode parentName="p">{`dissmised`}</inlineCode>{` from our `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` params (and set it to `}<inlineCode parentName="p">{`false`}</inlineCode>{` for a default value, and update the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS({ dismissed: defaultDismissed = false }) {
  const [dismissed, setDismissed] = useState(defaultDismissed);

  // ...
}
`}</code></pre>
    <p>{`Since we already have the const `}<inlineCode parentName="p">{`dismised`}</inlineCode>{`, we'll use `}<inlineCode parentName="p">{`defaultDismissed`}</inlineCode>{` as a throwaway variable.`}</p>
    <p>{`Now we're ready to create our `}<inlineCode parentName="p">{`submit`}</inlineCode>{` and `}<inlineCode parentName="p">{`dismiss`}</inlineCode>{` methods. First up, we need a way to update the `}<inlineCode parentName="p">{`score`}</inlineCode>{` variable AND call our provided `}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{` function.`}</p>
    <p>{`Inside of our `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` function, create a new arrow function for `}<inlineCode parentName="p">{`submit`}</inlineCode>{`, which takes in one argument `}<inlineCode parentName="p">{`score`}</inlineCode>{`, this should call `}<inlineCode parentName="p">{`setScore`}</inlineCode>{` with that value and if there is a `}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{` function provided, call it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const submit = (score) => {
  setScore(score);
  onSubmit && onSubmit(score);
};
`}</code></pre>
    <p>{`Running this will cause an error, `}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{` is undefined. Let's fix that by destructuring from our `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` arg object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS({ dismissed: defaultDismissed = false, onSubmit }) {
  // ...
}
`}</code></pre>
    <p>{`This now means we can provide an `}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{` function to our React hook. This is useful if we wish to pass on the score to an API, analytics platform or other aggregator (like our `}<a parentName="p" {...{
        "href": "https://graphcms.com"
      }}>{`GraphCMS`}</a>{` project) to track.`}</p>
    <p>{`Finally all that's left to do is return `}<inlineCode parentName="p">{`submit`}</inlineCode>{` inside our hook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS({ dismissed: defaultDismissed = false, onSubmit }) {
  // ...

  return [{ score, scored, dismissed }, { submit }];
}
`}</code></pre>
    <p>{`Now let's move onto the `}<inlineCode parentName="p">{`dismiss`}</inlineCode>{` function. It's almost identical to the `}<inlineCode parentName="p">{`submit`}</inlineCode>{` function, but instead of returning just the score to `}<inlineCode parentName="p">{`onDismiss`}</inlineCode>{`, we'll also provide the `}<inlineCode parentName="p">{`scored`}</inlineCode>{` boolean, and return these both inside an object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dismiss = () => {
  setDismissed(true);
  onDismiss && onDismiss({ scored, score });
};
`}</code></pre>
    <p>{`Like we did previous, we'll destructure `}<inlineCode parentName="p">{`onDismiss`}</inlineCode>{` from the `}<inlineCode parentName="p">{`useNPS`}</inlineCode>{` args and return `}<inlineCode parentName="p">{`dismiss`}</inlineCode>{` inside our hook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function useNPS({ dismissed: defaultDismissed = false, onSubmit, onDismiss }) {
  // ...

  return [
    { score, scored, dismissed },
    { submit, dismiss },
  ];
}
`}</code></pre>
    <p><strong parentName="p">{`Hurray! Our React hook is complete!`}</strong>{` Let's see the full code of our hook;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState } from "react";

function useNPS({ dismissed: defaultDismissed = false, onSubmit, onDismiss }) {
  const [dismissed, setDismissed] = useState(defaultDismissed);
  const [score, setScore] = useState(undefined);
  const scored = !!score;

  const submit = (score) => {
    setScore(score);
    onSubmit && onSubmit(score);
  };

  const dismiss = () => {
    setDismissed(true);
    onDismiss && onDismiss({ scored, score });
  };

  return [
    { score, scored, dismissed },
    { submit, dismiss },
  ];
}
`}</code></pre>
    <h2>{`Using our React hook`}</h2>
    <CodeSandbox codeSandboxId="8bii5" mdxType="CodeSandbox" />
    <p>{`Next steps might be to bundle this and publish to NPM 😍`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      